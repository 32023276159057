import React, { useEffect, useState, Fragment } from "react"
import LayoutGetStarted from "../components/layoutGetStarted"
import { useQueryParam, StringParam } from "use-query-params"
import firebase from "gatsby-plugin-firebase"
import Loading from "../components/loading"
import Meta from "../components/meta"

export default function SuccessPage() {
  const [loading, setLoading] = useState(true)
  const [invoiceId] = useQueryParam("invoiceId", StringParam);
  const [checkoutId] = useQueryParam("checkoutId", StringParam);
  const [orderNo, setOrderNo] = useState(null);

  useEffect(() => {
    let mounted = true
    firebase
      .functions()
      .httpsCallable('processPayment')({ checkoutId: checkoutId }).then((result) => {
        if (mounted) {
          if (result.data.order !== undefined)
            if (result.data.order.order_number !== undefined)
              setOrderNo(result.data.order.order_number)
          setLoading(false)
        }
      });
    return () => mounted = false;
  }, [])

  return (
    <Fragment>
      <Meta title="Payment" />
      <LayoutGetStarted activeScreen={5} setActiveScreen={() => { }}>
        {!loading ?
          <div className="grid place-items-center pt-10">
            <div className="text-xl font-medium pb-5">Thank you!</div>
            <div className="pt-5 pb-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
                <path className="fill-current text-delidoor-dark" d="M19.029 13h2.971l-.266 1h-2.992l.287-1zm.863-3h2.812l.296-1h-2.821l-.287 1zm-.576 2h4.387l.297-1h-4.396l-.288 1zm2.684-9l-.743 2h-1.929l-3.474 12h-11.239l-4.615-11h14.812l-.564 2h-11.24l2.938 7h8.428l3.432-12h4.194zm-14.5 15c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm5.9-7-.9 7c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z" />
              </svg>
            </div>
            {invoiceId === undefined ? null :
              <div className="text-sm text-center my-2">Your invoice no. is <span className="font-medium">{invoiceId}</span>.</div>
            }
            {orderNo === null ? null :
              <div className="text-sm text-center my-2">Your order no. is <span className="font-medium">{orderNo}</span>.</div>
            }
            <div className="my-10">
              <a
                className="cursor-pointer inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 focus:outline-none text-black bg-delidoor-light hover:bg-delidoor-dark"
                href="/account"
              >
                <div className="text-xs font-medium text-center">Back to my account</div>
              </a>
            </div>
          </div>
          :
          <Loading text="Please wait while we process your order!" />
        }
      </LayoutGetStarted>
    </Fragment>
  )
}